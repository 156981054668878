<mat-expansion-panel
  *ngIf="accessList && accessList.includes(item.display) && item.parent; else notParent"
  class="mat-elevation-z0"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="expansion-icon">{{ item.icon }}</mat-icon>
      {{ item.display }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-nav-item
    *ngFor="let sub of item.subMenu"
    [item]="sub"
    [roles]="roles"
    [accessList]="accessList"
    (selected)="selected.emit()"
  ></app-nav-item>
  <!-- <mat-nav-list *ngFor="let sub of item.subMenu">
    <a
      mat-list-item
      *ngIf="roles.includes(sub.role)"
      [routerLink]="[sub.link]"
      routerLinkActive="list-item-active"
      (click)="selected.emit(sub)"
    >
      <mat-icon>{{ sub.icon }}</mat-icon
      >{{ sub.display }}
    </a>
  </mat-nav-list> -->
</mat-expansion-panel>
<ng-template #notParent>
  <a
    mat-list-item
    *ngIf="accessList && accessList.includes(item.display)"
    [routerLink]="[item.link]"
    routerLinkActive="list-item-active"
    (click)="selected.emit(item)"
  >
    <mat-icon>{{ item.icon }}</mat-icon
    >{{ item.display }}
  </a>
</ng-template>
