<mat-sidenav-container
  fullscreen
  class="sidenav-container"
  [ngClass]="(hasFullView ? 'login-page': 'sidenav-container')"
  *ngIf="core.screen$ | async as screen"
>
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="screen.device === 'handset' ? 'dialog' : 'navigation'"
    [mode]="screen.device === 'handset' ? 'over' : 'over'"
  >
<!--    [opened]="screen.device !== 'handset'"-->
    <app-nav-tree
      [authenticated]="auth.isAuthenticated$ | async"
      [roles]="auth.roles$ | async"
      url="https://sharescape.io"
      [organizationLogoUrl]="logoUrl"
      [navItems]="core.navItems$ | async"
      [accessList]="accessList"
      [isLogoLoaded]="core.isLogoLoaded$ | async"
      (suggestProvider)="suggestProvider()"
      (referPatient)="referPatient()"
      (click)="navigate(screen)"
      (logoLoaded)="logoLoaded($event)"
    ></app-nav-tree>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header [hidden]="hasFullView"
      [loading]="app.loading$ | async"
      [compilingPDFs]="app.compilingPDFs$ | async"
      [defaultTitle]="'Portal'"
      [authenticated]="auth.isAuthenticated$ | async"
      [organizationNames]="auth.organizationNames$ | async"
      [roles]="auth.roles$ | async"
      [screen]="core.screen$ | async"
      [providerUser]="auth.providerUser$ | async"
      [environment]="app.environment$ | async"
      url="https://sharescape.io"
      [organizationLogoUrl]="'../../../../assets/sharescape-header-logo.png'"
      [isLogoLoaded]="core.isLogoLoaded$ | async"
      (toggleSideNav)="drawer.toggle()"
      (login)="login()"
      (logout)="logout()"
      (openEula)="eula()"
      (selectProvider)="selectProvider($event)"
      (logoLoaded)="logoLoaded($event)"
    ></app-header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <app-footer [hidden]="hasFullView"
      [screen]="core.screen$ | async"
      [year]="year"
      [privacyPolicy]="
        configuration.getConfigurationByType$('PrivacyPolicy') | async
      "
      (privacyPolicyClicked)="privacyPolicy()"
      (hiddenAction)="hiddenAction()"
    ></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
