<div class="container">
  <div class="col-1">
    <div class="logo">
      <img src="../../../../favicon.ico"/>
      <span style="font-size: 40px; color: #353635">
        ShareScape
      </span>
    </div>
    <p style="font-size: 17px;margin-left: 60px;">
      Record sharing made easy.
    </p>
  </div>
  <div class="col-2">
    <mat-card>
      <mat-progress-bar
        mode="indeterminate"
        [ngClass]="{hide: !(app.loading$ | async)}"
      ></mat-progress-bar>
      <mat-card-title>
        <div class="center-title logo" *ngIf="magicLink">
          <img src="../../../../favicon.ico" width="25px" height="25px"/>
          <span style="font-size: 24px;">
        ShareScape
        </span>
        </div>
        <div class="center-title" *ngIf="magicLink">
          <p style="font-size: 13px; margin-top: -3px;">Sign in</p>
          <p style="margin-top:-15px;">to access your portal</p>
        </div>
        <div class="center-title" *ngIf="!magicLink">
          <p style="font-size: 19px;font-weight: 500;">Sign in</p>
          <p style="margin-top: -10px;margin-bottom: 15px; font-size: 12px">to access your portal</p>
        </div>
      </mat-card-title>
      <mat-card-content>
        <form *ngIf="!magicLink" [formGroup]="loginForm" (ngSubmit)="login()">
          <p>
            <mat-form-field>
              <input
                type="text"
                matInput
                autocomplete="off"
                placeholder="Username"
                formControlName="username"
                required
              />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <input
                matInput
                placeholder="Password"
                [type]="hidePassword ? 'password' : 'text'"
                formControlName="password"
                autocomplete="off"
                required
              />
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
                hidePassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </mat-form-field>
          </p>
          <div class="button">
            <button type="submit" mat-flat-button color="accent">
              Login
            </button>
          </div>
        </form>

        <form *ngIf="magicLink" [formGroup]="magicLinkForm" (ngSubmit)="sendMagicLink()">
          <p>
            <mat-form-field>
              <input
                type="text"
                matInput
                autocomplete="off"
                placeholder="Username"
                formControlName="username"
                required
              />
            </mat-form-field>
          </p>

          <div class="magic-link-section">
            <button type="submit" mat-flat-button
                    color="primary"
                    [disabled]="!magicLinkForm.valid">
              Send Me a Magic Link
            </button>
          </div>
          <div class="use-password-footer">
            <button mat-flat-button *ngIf="magicLink" (click)="switchLink()"
                    color="accent">
              Use a Password
            </button>
          </div>
        </form>

      </mat-card-content>
      <mat-card-footer>

        <a class="magic-link-footer" *ngIf="!magicLink" (click)="switchLink()">
          Wait, go back!
        </a>

        <a class="magic-link-footer" (click)="magicLinkInfoDialog()" *ngIf="magicLink">
          What's a Magic Link?
        </a>

        <a class="forgot-password" [routerLink]="['/password-reset-request']">
          Forgot Password
        </a>
      </mat-card-footer>
    </mat-card>

    <mat-card-footer>
<!--      <a class="footer feedback" (click)="onOpenFeedback()">-->
<!--        Send Feedback-->
<!--      </a>-->
      <a class="footer" matTooltip="Email help@sharescape.io">
        Need help
        <span class="material-icons">help</span>
      </a>
      <a class="footer">
        <span class="material-icons">copyright</span>
        ShareScape LLC
      </a>
    </mat-card-footer>
  </div>

</div>
