import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import {OverlayContainer} from '@angular/cdk/overlay';
import {AuthFacade} from '@auth/+state';
import {PreferencesFacade} from '@preferences/+state';
import {ConfigurationFacade} from '@app/configuration/+state';
import {SnackbarService} from '@core/services';
import {MatDialog} from '@angular/material/dialog';
import {CoreService, LoggerService} from '../services';
import {
  EulaComponent,
  FeedbackComponent,
  InitLoginPromptComponent,
  MagicLinkInfoComponent,
  PrivacyPolicyComponent,
  ReferPatientComponent,
} from '../containers';
import * as fromActions from './core.actions';
import {openInitLoginPrompt} from './core.actions';
import {SuggestProviderComponent} from '@core/containers/suggest-provider/suggest-provider.component';
import {AngularFireAuth} from '@angular/fire/auth';
import {AppFacade} from '@app/+state';
import {ProfileFacade} from '@profile/+state';
import {PhoneNumbersFacade} from '@phone-numbers/+state';
import {UpdateProfileNag, UpdateProfileNagComponent} from '@core/components';
import {RouterFacade} from '@router/+state';
import {CoreFacade} from '@core/+state/core.facade';
import {Router} from '@angular/router';
import {SendMagicLinkComponent} from '@core/containers/send-magic-link/send-magic-link.component';

@Injectable()
export class CoreEffects {
  @Effect()
  setTheme$ = this.actions$.pipe(
    ofType(fromActions.setTheme),
    map((action) => {
      return fromActions.setThemeName({
        themeName: action.theme.name,
        isDark: action.theme.isDark,
      });
    })
  );

  // Set the UI theme from the passed in theme name.
  @Effect({ dispatch: false })
  setThemeName$ = this.actions$.pipe(
    ofType(fromActions.setThemeName),
    withLatestFrom(this.preferences.getPreferenceByType$('SelectedThemeName')),
    map(([action, pref]) => {
      const theme = action.themeName;
      const overlayContainerClasses = this.overlayContainer.getContainerElement()
        .classList;
      const themeClassesToRemove = Array.from(
        overlayContainerClasses
      ).filter((item: string) => item.includes('app-theme-'));
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(theme);
      // Only modify the Them Preference if action's theme
      // is different from the preference value.
      if (action.themeName !== pref.body.themeName) {
        const body: any = {
          type: pref.type,
          themeName: action.themeName,
          isDark: action.isDark,
        };
        this.preferences.savePreferenceByType('SelectedThemeName', body);
      }
    })
  );

  // The Effect responds to the Open Feedback Action fired from the Footer Component.
  @Effect()
  openFeedback$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openFeedback),
    exhaustMap((action) =>
      // Open a modal dialog to confirm the user wants to logout.
      this.dialogService
        .open(FeedbackComponent)
        .afterClosed()
        .pipe(
          map((send) => {
            if (send) {
              const feedback: any = { feedback: send };
              return fromActions.saveFeedback({ feedback });
            } else {
              return fromActions.cancelFeedback();
            }
          })
        )
    )
  );

  @Effect()
  saveFeedback$: Observable<Action> = this.actions$
    .pipe(ofType(fromActions.saveFeedback))
    .pipe(
      switchMap((action) => {
        return this.coreService.submitFeedback(action.feedback).pipe(
          map((feedback) => {
            return fromActions.saveFeedbackSuccess({ feedback });
          }),
          catchError((error) => of(fromActions.saveFeedbackError({ error })))
        );
      })
    );

  @Effect({ dispatch: false })
  saveFeedbackSuccess$ = this.actions$
    .pipe(ofType(fromActions.saveFeedbackSuccess))
    .pipe(
      map(({ feedback }) => {
        this.logger.notice(`Thank you for providing feedback.`, feedback);
        this.logger.alert(`Feedback provided.`, feedback);
      })
    );

  @Effect({ dispatch: false })
  saveFeedbackError$ = this.actions$.pipe(
    ofType(fromActions.saveFeedbackError),
    map(({ error }) => {
      const message = `An error occurred submitting feedback.`;
      this.logger.critical(message, error);
    })
  );

  // The Effect responds to the Open share ShareScape Action fired from the Header Component.
  @Effect()
  openShareScapeInvite: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openShareScapeInvite),
    exhaustMap((action) =>
      // Open a modal dialog to share shareScape
      this.dialogService
        .open(SuggestProviderComponent)
        .afterClosed()
        .pipe(
          map((submit) => {
            if (submit) {
              const shareScapeInvite: any = {shareScapeInvite: submit};
              return fromActions.submitShareScapeInvite({shareScapeInvite});
            } else {
              return fromActions.closeShareScapeInvite();
            }
          })
        )
    )
  );

  @Effect()
  submitShareScapeInvite$: Observable<Action> = this.actions$.pipe(
      ofType(fromActions.submitShareScapeInvite),
      withLatestFrom(this.afAuth.idToken),
      switchMap(([action, token]) => {
          return this.coreService.submitShareScapeInvite(action.shareScapeInvite.shareScapeInvite, token).pipe(
              map((shareScapeInvite) => {
                  return fromActions.submitShareScapeInviteSuccess({shareScapeInvite});
              }),
              catchError((error) => of(fromActions.submitShareScapeInviteError({error})))
          );
      })
  );

  @Effect({dispatch: false})
  submitShareInviteSuccess$ = this.actions$
    .pipe(ofType(fromActions.submitShareScapeInviteSuccess))
    .pipe(
      map(({shareScapeInvite}) => {
        this.logger.notice(`Suggestion sent successfully.`, shareScapeInvite);
      })
    );

  @Effect({dispatch: false})
  submitShareInviteError$ = this.actions$.pipe(
    ofType(fromActions.submitShareScapeInviteError),
    map(({error}) => {
      const message = `An error occur while sending suggestion.`;
      this.logger.critical(message, error);
    })
  );

  // The Effect responds to the Open Refer Patient Action fired from the nav Component.
  @Effect()
  openReferPatientDialog$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openReferPatientDialog),
    exhaustMap((action) =>
      // Open a modal dialog to refer patient
      this.dialogService
        .open(ReferPatientComponent)
        .afterClosed()
        .pipe(
          map((submit) => {
            if (submit) {
              const referPatient: any = {referPatient: submit};
              return fromActions.submitReferPatient({referPatient});
            } else {
              return fromActions.closeReferPatientDialog();
            }
          })
        )
    )
  );

  @Effect()
  submitReferPatient$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.submitReferPatient),
    withLatestFrom(this.afAuth.idToken),
    switchMap(([action, token]) => {
      return this.coreService.submitReferPatient(action.referPatient.referPatient, token).pipe(
        map((referPatient) => {
          return fromActions.submitReferPatientSuccess({referPatient});
        }),
        catchError((error) => of(fromActions.submitReferPatientError({error})))
      );
    })
  );

  @Effect({dispatch: false})
  submitReferPatientSuccess$ = this.actions$
    .pipe(ofType(fromActions.submitReferPatientSuccess))
    .pipe(
      map(({referPatient}) => {
        this.logger.notice(`Refer patient successfully.`, referPatient);
      })
    );

  @Effect({dispatch: false})
  submitReferPatientError$ = this.actions$.pipe(
    ofType(fromActions.submitReferPatientError),
    map(({error}) => {
      const message = `An error occur while referring patient.`;
      this.logger.critical(message, error);
    })
  );

  // The Effect responds to the Open Privacy Policy Action fired from the Footer Component.
  @Effect()
  openPrivacyPolicyDialog$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openPrivacyPolicyDialog),
    exhaustMap((action) =>
      this.dialogService
        .open(PrivacyPolicyComponent, {
          data: { title: 'ShareScape Privacy Policy' },
        })
        .afterClosed()
        .pipe(map((res) => fromActions.closePrivacyPolicyDialog()))
    )
  );
  // The Effect responds to the Open EULA Action
  @Effect()
  openEulaDialog$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openEulaDialog),
    withLatestFrom(this.configuration.getConfigurationByType$('EULA')),
    exhaustMap(([action, configuration]) =>
      this.dialogService
        .open(EulaComponent, {
          disableClose: true,
          data: {
            title: 'End User License Agreement',
            titleShort: 'EULA',
            configuration,
          },
        })
        .afterClosed()
        .pipe(
          map((accept) => {
            if (accept) {
              return fromActions.eulaAccepted();
            } else {
              return fromActions.eulaCancelled();
            }
          })
        )
    )
  );

  @Effect()
  eulaAccepted$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.eulaAccepted),
    withLatestFrom(this.auth.uid$),
    withLatestFrom(this.auth.providerId$),
    switchMap(([[{ type }, uid], providerId]) =>
      this.coreService.acceptEula(uid).pipe(
        switchMap((res) => {
          if (providerId) {
            return [fromActions.saveEulaAcceptedSuccess()];
          } else {
            return [fromActions.saveEulaAcceptedSuccess(), openInitLoginPrompt()];
          }
        }),
        catchError((error) => of(fromActions.saveEulaAcceptedError({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  saveEulaError$ = this.actions$.pipe(
    ofType(fromActions.saveEulaAcceptedError),
    map(({ error }) => {
      const message = 'An error occurred accepting EULA.';
      this.logger.error(message, error);
    })
  );

  @Effect({ dispatch: false })
  saveEulaSuccess$ = this.actions$
    .pipe(ofType(fromActions.saveEulaAcceptedSuccess))
    .pipe(map(() => this.logger.notice(`End User License Accepted.`)));

  // Search provider location
  @Effect()
  searchOrganization$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.searchOrganization),
    tap(() => this.app.setLoading(true)),
    switchMap(({search}) => {
      return this.coreService.searchProviderLocation(search).pipe(
        map((searchRes) => {
          return fromActions.searchOrganizationSuccess({searchRes});
        }),
        catchError((error) => of(fromActions.searchOrganizationError({error})))
      );
    })
  );

  @Effect({dispatch: false})
  searchOrganizationSuccess$ = this.actions$.pipe(
    ofType(fromActions.searchOrganizationSuccess),
    map(() => this.app.setLoading(false))
  );

  @Effect({dispatch: false})
  searchOrganizationError$ = this.actions$.pipe(
    ofType(fromActions.searchOrganizationError),
    tap(() => this.app.setLoading(false)),
    map(({error}) => {
      const message = `An error occurred loading provider location.`;
      this.logger.critical(message, error);
    })
  );

  // The Effect responds to the Open Initial login prompt Action.
  @Effect()
  openInitLoginPrompt$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openInitLoginPrompt),
    withLatestFrom(this.auth.uid$),
    exhaustMap(([_, uid]) =>
      this.dialogService
        .open(InitLoginPromptComponent)
        .afterClosed()
        .pipe(
          map((data) => {
            if (data) {
              const info = {...data};
              const input = info.user;
              input.id = uid;
              info.phoneNumber.user_id = uid;
              if (info.phoneNumber.phoneNumber) {
                this.phoneNumbers.createUserPhoneNumber(info.phoneNumber);
              }
              return fromActions.updateProfile({input});
            } else {
              return fromActions.cancelInitLoginPrompt();
            }
          })
        )
    )
  );

  @Effect({dispatch: false})
  updateProfile$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updateProfile),
    switchMap(({ input }) => {
      return this.coreService.update(input).pipe(
        map((user) => fromActions.updateProfileSuccess({ user })),
        catchError((error) => of(fromActions.updateProfileError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  updateProfileSuccess$ = this.actions$.pipe(
    ofType(fromActions.updateProfileSuccess),
    map(() => this.logger.notice('Your profile changes have been saved.'))
  );

  @Effect({ dispatch: false })
  updateProfileError$ = this.actions$.pipe(
    ofType(fromActions.updateProfileError),
    map(({ error }) => {
      const message = 'An error occurred updating profile.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  profileUpdateReminderNag$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openProfileUpdateReminderNag),
    withLatestFrom(this.profile.profile$),
    withLatestFrom(this.core.profileUpdateReminder$),
    filter(([[_, profile], reminder]) => {
      if (profile) {
        const route = this.currentRoute.url;
        const data = !!(profile && profile.first_name && profile.last_name);
        const weeks = reminder ? this.getWeeks(reminder) : 2;
        return (route !== '/profile' && !data && weeks >= 2);
      }
      return !!profile;
    }),
    exhaustMap(([profile]) => {
        const data: UpdateProfileNag = {
          title: `Friendly reminder from ShareScape!`,
          body: `Hey there!\n We noticed you haven't filled out your profile information,\n please take a moment to complete it.`,
        };
        return this.dialogService
          .open(UpdateProfileNagComponent,
            {disableClose: true, data}
          )
          .afterClosed()
          .pipe(
            map((isUpdate) => {
              if (isUpdate) {
                this.router.go({path: [`profile`]});
              }
              const value = new Date();
              return fromActions.profileUpdateReminder({value});
            }),
          );
      }
    )
  );

  // The Effect responds to the Open What's a magic link Action.
  @Effect()
  magicLinkInfoDialog$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openMagicLinkInfoPrompt),
    exhaustMap((action) =>
      this.dialogService
        .open(MagicLinkInfoComponent)
        .afterClosed()
        .pipe(map((res) => fromActions.closeMagicLinkInfoPrompt()))
    )
  );

  @Effect()
  sendMagicLinkDialog$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.openSendMeMagicLinkPrompt),
    exhaustMap((action) =>
      this.dialogService
        .open(SendMagicLinkComponent)
        .afterClosed()
        .pipe(map((res) => fromActions.closeSendMeMagicLinkPrompt()))
    )
  );

  @Effect({dispatch: false})
  showSnackBar$ = this.actions$.pipe(
    ofType(fromActions.showSnackBar),
    map((action) => this.snackbar.showSnackBar(action.snackBarInfo))
  );

  @Effect({ dispatch: false })
  showSnackBarError$ = this.actions$.pipe(
    ofType(fromActions.showSnackBarError),
    map((action) => this.snackbar.showSnackBar(action.snackBarInfo))
  );

  @Effect()
  log$: Observable<Action> = this.actions$.pipe(ofType(fromActions.log)).pipe(
    mergeMap(({ message }) => {
      return this.logger.log(message).pipe(
        map((response) => {
          return fromActions.logSuccess({ response });
        }),
        catchError((error) => of(fromActions.logError({ error })))
      );
    })
  );

  @Effect()
  logout$: Observable<Action> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => !authenticated),
    map(() => fromActions.clearState())
  );

  getWeeks(reminder) {
    const currentDate = new Date();
    const oldDate = new Date(reminder);
    const time = currentDate.getTime() - oldDate.getTime();
    const days = Math.floor(time / (1000 * 3600 * 24));
    const weeks = days / 7;
    return weeks;
  }
  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private afAuth: AngularFireAuth,
    private logger: LoggerService,
    private coreService: CoreService,
    private preferences: PreferencesFacade,
    private configuration: ConfigurationFacade,
    private snackbar: SnackbarService,
    private overlayContainer: OverlayContainer,
    private dialogService: MatDialog,
    private app: AppFacade,
    public profile: ProfileFacade,
    private phoneNumbers: PhoneNumbersFacade,
    private router: RouterFacade,
    private core: CoreFacade,
    private currentRoute: Router
  ) {}
}
