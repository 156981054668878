// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  environment: 'DEMO',
  firebaseConfig: {
    apiKey: 'AIzaSyBBs0bXFLjE-bE7xr78TF_VwD-SUCny8QQ',
    authDomain: 'sharescapeio-demo.firebaseapp.com',
    databaseURL: 'https://sharescapeio-demo.firebaseio.com',
    projectId: 'sharescapeio-demo',
    storageBucket: 'sharescapeio-demo.appspot.com',
    messagingSenderId: '890117747635',
    appId: '1:890117747635:web:115effef7d2a3caa0cd1c6',
    measurementId: 'G-C0W66RQX2C',
  },
  hasura: {
    https: 'https://demo-api.sharescape.io/v1/graphql',
    wss: 'wss://demo-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://demo-api.sharescape.io',
  logToConsole: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
